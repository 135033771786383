import styled, { themeGet } from "@styled";
import quoteIcon from "@data/images/icons/quoteSmall-icon.png";

export const TrQualityProcessWrapper = styled.section`
  padding: 85px 0;
`;
export const TrQualityProcessInner = styled.div`
  .reverseRow {
    margin-top: 80px;
    &:nth-child(odd) {
      flex-direction: row-reverse;
    }
    &:not(:last-child) {
      margin-bottom: 120px;
    }
  }
`;
export const TrQualityProcessBox = styled.div`
  display: flex;
`;
export const TrQualityProcessIconWrap = styled.div`
  background: url(${quoteIcon});
  min-width: 70px;
  width: 70px;
  height: 70px;
  margin-top: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.themeColor};
  font-size: 30px;
  font-weight: bold;
`;
export const TrQualityProcessCount = styled.span`
  margin-right: 14px;
  margin-bottom: 4px;
`;
export const TrQualityProcessTextWrap = styled.div`
  h5 {
    text-transform: uppercase;
    font-weight: 400;
  }
  p {
    margin-top: 40px;
  }
  a {
    color: ${themeGet("colors.primary")};
    transition: ${themeGet("transition")};
    &:hover {
      transform: translateY(-3px);
    }
  }
`;
