import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import IntroArea from "@components/introarea/layout-1";
import TranslationQualityCriteria from "@containers/translation-quality-management/translation-quality-criteria";
import TranslationQualityProcess from "@containers/translation-quality-management/translation-quality-process";
import CtaArea from "@containers/cta/layout-02";
import CaseStudy from "@containers/global/case-study";
import ContactArea from "@containers/contact-us";
import { caseStudiesData } from "@constants";

const TranslationQualityPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo title="Translation Quality Management" />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["translation-quality-page-header"]} />
        <IntroArea data={content["translation-quality-intro"]} />
        <TranslationQualityCriteria
          data={content["translation-quality-criteria-body"]}
        />
        <CtaArea layout={2} data={content["cta-data1"]} />
        <TranslationQualityProcess
          data={content["translation-quality-process-body"]}
        />
        <CtaArea data={content["cta-data2"]} />
        <CaseStudy data={caseStudiesData} />
        <ContactArea Layout={2} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query translationQualityPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(
      title: { eq: "translation-quality-management" }
      pageType: { eq: "innerpage" }
    ) {
      content {
        ...PageContent
      }
    }
  }
`;

TranslationQualityPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default TranslationQualityPage;
