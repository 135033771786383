import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import SectionTitle from "@ui/section-title";
import Text from "@ui/text";
import Heading from "@ui/heading";
import Image from "@ui/image";
import Anchor from "@ui/anchor";

import {
  TrQualityProcessWrapper,
  TrQualityProcessInner,
  TrQualityProcessBox,
  TrQualityProcessIconWrap,
  TrQualityProcessCount,
  TrQualityProcessTextWrap,
} from "./style";

export default function TranslationQualityProcess({
  headingStyle,
  subheadingStyle,
  descStyle,
  data,
}) {
  return (
    <TrQualityProcessWrapper>
      <TrQualityProcessInner>
        <Container>
          <Row>
            <Col lg={12}>
              <SectionTitle
                subtitle={data.section_title.subtitle}
                title={data.section_title.title}
                description={data.section_title.description}
              />
            </Col>
          </Row>
          {!!data.items &&
            data.items.map((el, index) => (
              <Row className="reverseRow" key={index}>
                <Col lg={6}>
                  <TrQualityProcessBox>
                    <TrQualityProcessIconWrap>
                      <TrQualityProcessCount>{el.id}</TrQualityProcessCount>
                    </TrQualityProcessIconWrap>
                    <TrQualityProcessTextWrap>
                      <Heading {...subheadingStyle}>{el.subject}</Heading>
                      <Heading {...headingStyle}>{el.title}</Heading>
                      <Text className="mt-5" {...descStyle}>
                        {el.description}
                      </Text>
                      {!!el.path && (
                        <Anchor path={el.path} key={index}>
                          Read More <i className="ms-2 fal fa-angle-right"></i>
                        </Anchor>
                      )}
                    </TrQualityProcessTextWrap>
                  </TrQualityProcessBox>
                </Col>
                <Col lg={6}>
                  <Image src={el.images[0]?.src} alt={el.images[0]?.alt} />
                </Col>
              </Row>
            ))}
        </Container>
      </TrQualityProcessInner>
    </TrQualityProcessWrapper>
  );
}

TranslationQualityProcess.propTypes = {
  SectionTitleStyle: PropTypes.object,
  descStyle: PropTypes.object,
};
TranslationQualityProcess.defaultProps = {
  subheadingStyle: {
    as: "h5",
    fontSize: "15px",
    color: "#f94f19",
    fontweight: 400,
  },
  headingStyle: {
    as: "h3",
    fontSize: "30px",
    fontweight: 700,
  },
  descStyle: {
    fontSize: "17px",
  },
};
